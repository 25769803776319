var lightboxOpen = false;

$(document).ready(function() {


	if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){ //Dirty Firefox bugfix caused by transforms & mix-blend-mode
		//
	}

	if(isIE()) {
		recalcSvgHeight();
	}

	if(window.innerWidth > 768) {
		//Fenster (desktop)
		fixOriginPoints(".fenster-single","fenster-single-animateable");
		fixOriginPoints("#Group_1549 > .cls-410","schild-schrauber-animateable",null, 0, {
			height: 65.16032409667969,
			width: 80.64495849609375,
			x: 545.4400024414062,
			y: 190.13084411621094
		});
		fixOriginPoints("#Group_1549 > .cls-414","schild-saege-animateable",null, 0,{
			height: 84.62319946289062,
			width: 72.86470794677734,
			x: 80.58551788330078,
			y: 174.48678588867188
		});
		fixOriginPoints("#Group_1549 > .cls-412","schild-schluessel-animateable",21, 41, {
			height: 81.52996063232422,
			width: 41.8751220703125,
			x: 619.0414428710938,
			y: 74.05998992919922
		});
		fixOriginPoints("#Group_1549 > .cls-400","schild-hammer-animateable",8, 75,{
			height: 80.92619323730469,
			width: 39.06617736816406,
			x: 36.25,
			y: 74.52000427246094
		});

		//fixOriginPoints("#cable-car-line ~ #cable-car-2","cable-car-2-animateable",null, 0);
		//fixOriginPoints("#cable-car-line ~ #cable-car-1","cable-car-1-animateable",null, 0);

		fixOriginPoints("#Group_1469","santa-animateable",null,null,{
			height: 40.37762451171875,
			width: 50.67803955078125,
			x: 175.57974243164062,
			y: 82.67575073242188
		});
		fixOriginPoints("#Group_1471","rehntier1-animateable",null,null,{
			height: 57.88502883911133,
			width: 40.441566467285156,
			x: 10.229528427124023,
			y: 0.8199984431266785
		});
		fixOriginPoints("#Group_1472","rehntier2-animateable",null,null,{
			height: 56.65999984741211,
			width: 38.521888732910156,
			x: 67.9774169921875,
			y: 8.350001335144043
		});
		fixOriginPoints("#Group_1473","rehntier3-animateable",null,null, {
			height: 54.637733459472656,
			width: 45.27172088623047,
			x: 125.86840057373047,
			y: 31.14997100830078
		});
		fixOriginPoints("#Path_24017","santa-rope-animateable",null,null, {
			height: 51.922813415527344,
			width: 174.9700164794922,
			x: 22.260000228881836,
			y: 35.30718994140625
		});

		fixOriginPoints("#mond","moon-animateable",null,null, {
			height: 71.3337173461914,
			width: 58.78847885131836,
			x: 0.0015179216861724854,
			y: 0
		});
		fixOriginPoints("#snowman-2 > #Group_844","snowman-arm-right-animateable",3,50, {
			height: 50.219993591308594,
			width: 82.40998840332031,
			x: 192.4600067138672,
			y: 116.51000213623047
		});
		fixOriginPoints("#snowman-2 > #Group_845","snowman-arm-left-animateable",90,73, {
			height: 73.15816497802734,
			width: 90.43898010253906,
			x: 3.9410204887390137,
			y: 95.8618392944336
		});

		fixOriginPoints("#person-skate-3 #Group_953","person-skate-animateable",90,177, {
			height:177.53561401367188,
			width: 152.05718994140625,
			x: 222.14999389648438,
			y:0
		});

		fixOriginPoints("#cable-car > #cable-car-2","cable-car-animateable",0,0, {
			height: 237.06741333007812,
			width: 1299.5699462890625,
			x: 0.2800000011920929,
			y: 0.39000001549720764
		});
		fixOriginPoints("#cable-car > #cable-car-2 #cable-car-1","cable-car-1-animateable",12,5, {
			height:	53.80999755859375,
			width: 24.209991455078125,
			x: 128.3800048828125,
			y: 177.8699951171875
		});
		fixOriginPoints("#cable-car > #cable-car-2 #cable-car-1 #Path_24226-2","cable-car-1-top-animateable",12,5, {
			height: 6.5900115966796875,
			width: 22.30999755859375,
			x: 128.3800048828125,
			y: 177.8699951171875
		});

		fixOriginPoints("#cable-car > #cable-car-2 #cable-car-2","cable-car-2-animateable",22.5,11, {
			height:	103.36000061035156,
			width: 45.1300048828125,
			x: 1102.47998046875,
			y: 93.35000610351562
		});
		fixOriginPoints("#cable-car > #cable-car-2 #cable-car-2 #Path_24226","cable-car-2-top-animateable",22.5,11, {
			height: 19.919998168945312,
			width: 39.080078125,
			x: 1107.5599365234375,
			y: 93.35000610351562
		});
	}
	else {
		//Fenster (mobil)
		fixOriginPoints(".fenster-single--mobile","fenster-single-animateable");
	}


	$(window).on("resize", function() {
		if(window.innerWidth > 768) {
			//Fenster (desktop)
		//	fixOriginPoints(".fenster-single","fenster-single-animateable");
		}/*
		else {
			//Fenster (mobil)
			fixOriginPoints(".fenster-single--mobile","fenster-single-animateable");
		}*/
		if(isIE()) {
			recalcSvgHeight();
		}
	});

	setDoorClickEvent();

	setLightBoxDefaults();

	if(!isMobile()) {
		initSnowFlakes();
		$.fn.snow();
	}

});

function isIE() {
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");

	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
	{
		return true;
	}
	return false;
}


function recalcSvgHeight() {
	var wrapperWidth = $("#promo-adventskalender").eq(0).width();
	var calcedHeight = (1294 / 1534) * wrapperWidth;
	$("#adventkalender-svg").eq(0).css({
		width: wrapperWidth,
		height: calcedHeight
	})
}

function setDoorClickEvent() {
	var fenster = $(".fenster-single--current-day");
	fenster.on("click", function(e) {
		e.preventDefault();
		if (!lightboxOpen) {
			lightboxOpen = true;
			$.featherlight($("#lightbox-form").eq(0));
			initFormValidation();
			if(!isMobile() && !isIE()) {
				initRunningLight();
			}

		}
	})
}

function clearRunningLight() {
	if(window.runningLightInterval) {
		clearInterval(window.runningLightInterval);
	}
	if(window.runningLightTimeout) {
		clearTimeout(window.runningLightInterval)
	}
}
function initRunningLight() {
	clearRunningLight();
	var container = document.getElementById("sterne");
	var bulbs = container.querySelectorAll(".stern");
	var bulbsLen = bulbs.length;

	var currentCounter = 0;
	var flipToggle = true;
	window.runningLightTimeout = setTimeout(function() {
		window.runningLightInterval = setInterval(function() {
			if(currentCounter < bulbsLen) {
				if(flipToggle) {
					bulbs[currentCounter].classList.add("stern-glow");
				}
				else {
					//bulbs[currentCounter].classList.remove("stern-glow");
				}
				currentCounter++;
			}
			else {
				currentCounter = 0;
				flipToggle = !flipToggle;
			}
		},500)
	},1000);

}

function featherlightFadeIn(_this) {
	$(_this.$instance).addClass("featherlight-visible")
}
var google_widget;
function setLightBoxDefaults() {
    $.featherlight.defaults.beforeOpen = function() {
        $('body').css('overflow', 'hidden');
    };

    $.featherlight.defaults.afterOpen = function(event) {
        var captcha = $(".featherlight-content .g-recaptcha");
	    if(this.target) {

		    if (captcha.length == 1 && this.target.hasClass('lightbox-form')) {
		        var form = captcha.closest('form');
		        google_widget = grecaptcha.render(captcha.attr("id"), {
	                'sitekey': '6LfPBu4ZAAAAADgSVD-VoXlwitUuZCd375HxVsiR',
			        'size': 'invisible',
			        'callback': function(token) {
				        form.submit()
			        }

	            });
	        }
		    else if(this.target.attr("id") === "lightbox-danke"){
			    /**
			     * Execute Conversion script
			     */


			    gtag('event', 'conversion', {
				    'allow_custom_scripts': true,
				    'send_to': 'DC-8415100/hageb0/advent+standard'
			    });

			    var ns = $("<noscript></noscript>");
			    var nsPic = $('<img src="https://ad.doubleclick.net/ddm/activity/src=8415100;type=hageb0;cat=advent;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/>');
			    ns.append(nsPic);
			    $(".featherlight-content #lightbox-danke").append(ns);
		    }
	    }
	    featherlightFadeIn(this);
    };

    $.featherlight.defaults.beforeClose = function() {
        var agb = $(".featherlight-content #lightbox-agb");
        if (agb.length == 0) {
            $('body').css('overflow', 'auto');
            lightboxOpen = false;
        }
	    clearRunningLight();
    };

    $.featherlight.defaults.root = '.lightbox-container';
    $.featherlight.defaults.closeIcon = '';
}


function initFormValidation() {

    var registrationform = $(".featherlight-content .registration-form");

	initNewsletter();

	function initNewsletter() {
		var newsletterCheckbox = $(".featherlight-content .js--nl-accept input");
		var _emailField = $(".featherlight-content .js--require-nl--email");
		newsletterCheckbox.on("change", function() {
			var useNL = this.checked;
			if(useNL) {
				_emailField.addClass("jubelschreie-required");
				_emailField.prev().text("E-Mail*:");
			}
			else {
				_emailField.prev().text("E-Mail:");
				var errLabel = _emailField.next();
				if(errLabel && errLabel.hasClass("error")) {
					errLabel.hide(0);
				}
				_emailField.removeClass("jubelschreie-required");
				_emailField.removeClass("error");
			}
		})
	}

    //Für Form Validate
    window.jbsValidateForm = registrationform.validate({
        submitHandler: function(form) {
            if (grecaptcha.getResponse(google_widget) === "") {
	            grecaptcha.execute(google_widget);
	            return false;
            } else {
                ajaxRequest();
            }
        },
        errorPlacement: function(error, element) {
          error.appendTo(element[0].parentNode);
        },
        errorClass: "error",
        errorElement: "label",
        messages: {
            "anrede": "Bitte w&auml;hlen Sie eine Anrede.",
            "vname": "Bitte geben Sie Ihren Vornamen ein.",
            "nname": "Bitte geben Sie Ihren Nachnamen ein.",
            "email": "Bitte geben Sie Ihre E-Mail Adresse ein.",
            "agb-accept": "Bitte best&auml;tigen Sie die Bedingungen.",
            "plz": "Bitte geben Sie eine g&uuml;ltige PLZ ein.",
            "ort": "Bitte geben Sie einen g&uuml;ltigen Ort ein.",
            "strasse": "Bitte geben Sie eine g&uuml;ltige Straße ein."
        }
    });


    $.validator.addMethod("pattern", function(value, element, param) {
        if (this.optional(element)) {
            return true;
        }
        if (typeof param === "string") {
            param = new RegExp("^(?:" + param + ")$");
        }
        return param.test(value);
    }, "Invalid format.");

    $.validator.addMethod("cRequired", $.validator.methods.required);

    $.validator.addClassRules("jubelschreie-required", {
        cRequired: true
    });

    registrationform.on("submit", function(e) {
        e.preventDefault();
    });

}

function ajaxRequest() {
    var form = $(".featherlight-content .registration-form");

    var agb = 0;
    if (form.find("input[name='agb-accept']").is(':checked')) {
        agb = 1;
    }
	var _newsletter = "";
	if (form.find("input[name='newsletter']").is(':checked')) {
		_newsletter = form.find("input[name='newsletter'] ~ span").text();
	}

	var torNr = form.attr("data-tag-nr");
    //Form Felder als object
    var formData = {
        "title": form.find("select[name='anrede']").val(),
        "firstname": form.find("input[name='vname']").val(),
        "lastname": form.find("input[name='nname']").val(),
        "email": form.find("input[name='email']").val(),
        "street": form.find("input[name='strasse']").val(),
        "zip": form.find("input[name='plz']").val(),
        "city": form.find("input[name='ort']").val(),
		"door": torNr,
	    "newsletter": _newsletter,
        "teilnahme": agb
    };

    //Ajax Form POST
	formData.grepcaptcha = grecaptcha.getResponse(google_widget);



	try {
	    $.ajax({
	            type: 'POST',
	            beforeSend: function(request) {
				  request.setRequestHeader("pd-api-key", "pd002#B154kn*5p!");
				},
		        url: 'https://hagebau.secured-api.bruns.dev/subscribe',
	            data: JSON.stringify(formData), //Zu Sendene Daten
	            dataType: 'json', //Format der Antwort
	            processData: false,
	            contentType: "application/json",
	            encode: false
	        })
	        .done(function(data) {
	            console.log("data");
	            console.log(data);
		        if(data === "Done") {
	                $.featherlight.current().close();
			        $.featherlight($('#lightbox-danke'));

	            } else {
			        $.featherlight.current().close();
	                $.featherlight($('#lightbox-error'));
	            }
	        }).fail(function(data) {
		        $.featherlight.current().close();
			    $.featherlight($('#lightbox-error'));
	    });
	}
	catch(e) {
		$.featherlight($('#lightbox-error'));
	}
}

function initSnowFlakes() {
	/**
	 * jquery.snow - jQuery Snow Effect Plugin
	 *
	 * Available under MIT licence
	 *
	 * @version 1 (21. Jan 2012)
	 * @author Ivan Lazarevic
	 * @requires jQuery
	 * @see http://workshop.rs
	 *
	 * @params flakeChar - the HTML char to animate
	 * @params minSize - min size of snowflake, 10 by default
	 * @params maxSize - max size of snowflake, 20 by default
	 * @params newOn - frequency in ms of appearing of new snowflake, 500 by default
	 * @params flakeColors - array of colors , #FFFFFF by default
	 * @params durationMillis - stop effect after duration
	 * @example $.fn.snow({ maxSize: 200, newOn: 1000 });
	 */
	$.fn.snow = function(options){

		var $flake 			= $('<div class="flake" />').css({'position': 'absolute', 'top': '-50px'}),
			documentHeight 	= $('#flake-wrapper').height(),                                                 //Edit BM
			documentWidth	= $('#flake-wrapper').width(),                                                  //Edit BM
			defaults		= {
				flakeChar	: "&#8226;",
				minSize		: 10,
				maxSize		: 25,
				newOn		: 225,
				flakeColor	: ["#ffffff"]
			},
			options			= $.extend({}, defaults, options);

		$flake.html(options.flakeChar);

		var interval		= setInterval( function(){
			var startPositionLeft 	= Math.random() * documentWidth - 100,
				startOpacity		= 0.5 + Math.random(),
				sizeFlake			= options.minSize + Math.random() * options.maxSize,
				endPositionTop		= documentHeight - defaults.maxSize - 40,
				endPositionLeft		= startPositionLeft - 100 + Math.random() * 200,
				durationFall		= documentHeight * 7 + Math.random() * 4000;
			$flake.clone().appendTo('#flake-wrapper').css(
				{
					left: startPositionLeft,
					opacity: startOpacity,
					'font-size': sizeFlake,
					color: options.flakeColor[Math.floor((Math.random() * options.flakeColor.length))]
				}
			).animate(
				{
					top: endPositionTop,
					left: endPositionLeft,
					opacity: 0.0
				},
				durationFall,
				'linear',
				function() {
					$(this).remove()
				}
			);
		}, options.newOn);
	};
}

function isMobile() {
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		return true;
	}
	return false;
}

function fixOriginPoints(selector, additionalClass, xPos, yPos, fakeBBox) {
	var elements = $(selector);
	for(var i = 0; i < elements.length; i++) {
		var bBox = fakeBBox || elements.eq(i).get(0).getBBox();
		var fX,fY;

		if(xPos == undefined || xPos == null) { fX = bBox.x + (0.5 * bBox.width); }
		else { fX = bBox.x + xPos; }

		if(yPos == undefined || yPos == null) {	fY = bBox.y + (0.5 * bBox.height); }
		else { fY = bBox.y + yPos; }

		elements[i].style.transformOrigin = fX + "px " +  fY + "px";
		if(additionalClass) {
			if(elements[i].classList) {
				elements[i].classList.add(additionalClass);
			}
		}

	}
}
